<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="120px" :model="formLabelAlign" class="from">
            <el-form-item label="状态：">
              <el-select v-model="formLabelAlign.status" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个记录</span>
            <span class="span2">已选择 {{ more_List.length }} 个记录</span>
            <i @click="way_tableData" class="span_i el-icon-refresh"
              >数据更新</i
            >
          </p>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="address" label="发生地点"></el-table-column>
            <el-table-column prop="describe" label="事件描述"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待办</span>
                <span v-if="scope.row.status == 1">未办结</span>
                <span v-if="scope.row.status == 2">已办结</span>
              </template>
            </el-table-column>
            <el-table-column prop="pic" label="图片">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px; object-fit: cover"
                  :src="scope.row.pic[0]"
                  :preview-src-list="scope.row.pic"
                ></el-image>
              </template>
            </el-table-column>

            <el-table-column prop="addtime" label="创建时间"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link
                  v-if="scope.row.fenuserarr == null"
                  class="list_sty"
                  type="primary"
                  @click="on_particulars(scope.row.id)"
                  >分配</el-link
                >
                <span v-else style="color: #ccc">已分配</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="分配人员"
        :visible.sync="dialogVisible"
        width="300px"
        :before-close="handleClose"
      >
        <el-select
          style="width: 100%"
          v-model="fenuserarr"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.nickname"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关 闭</el-button>
          <el-button type="primary" @click="onSubmit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
  <script>
import table_minin from "../../assets/minin/table_minin";
import { patrolList, patrolPost, userList } from "../../assets/request/api";
export default {
  mixins: [table_minin],
  data() {
    return {
      dialogVisible: false,
      formLabelAlign: {
        status: ""
      },
      options: [
        {
          id: 0,
          name: "待办"
        },
        {
          id: 1,
          name: "未办结"
        },
        {
          id: 2,
          name: "已办结"
        }
      ],
      id: 0,
      users: [], //人员列表
      fenuserarr: [] //选中的人
    };
  },
  created() {
    this.url = patrolList;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {},
    //详情弹框
    on_particulars(id) {
      this.id = id;
      this.fenuserarr = [];
      this.dialogVisible = true;
      userList().then(res => {
        this.users = res.data;
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    onSubmit() {
      const data = {
        id: this.id,
        fenuserarr: this.fenuserarr
      };
      patrolPost(data).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          this.handleClose();
          this.way_tableData();
        } else {
          this.$message.error(res.msg);
          this.handleClose();
        }
      });
    }
  }
};
</script>
  <style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px;
  color: #333;
}
.boxs {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    display: flex;
    margin-bottom: 20px;
    .lable {
      white-space: nowrap;
    }
  }
}

::v-deep .el-dialog {
  overflow-y: scroll;
  max-height: 550px;
  min-width: none;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  .block {
    margin-right: 10px;
  }
}
::v-deep .el-dialog {
  min-width: 400px;
}
</style>
  